import { CustomAttr } from './plant.model';

export class PartnerModel {
  Id: number;
  CompanyName: string;
  Identifier: string;
  IsActive: boolean;
  Address1: string;
  Address2: string;
  City: string;
  StateId: string = "0";
  Zip: string;
  Logo: string;
  PortalLogo: string;
  Url: string;
  Taxable: boolean;
  EcommerceEnabled: boolean;
  PartnerTypeId: number = 0;
  PartnerQRUrl: string = '';
  PartnerQRCode: string = '';
  ContractStart: Date;
  ContractEnd: Date;
  BannerAd: string;
  CenterLatitude: number;
  CenterLongitude: number;
  CenterZoom: number;
  RestrictCompanionPlants: boolean;
  RestrictSuggestAPlant: boolean;
}

export class PartnerAttributes {
  public Types: CustomAttr[];
  public States: CustomAttr[];
}

export class PartnerWidgetModel {
  Id: number;
  WidgetBackgroundColor: string;
  WidgetTextColor: string;
  WidgetLogo: string;
  WidgetLogoAngle: number;
  WidgetStatus: boolean;
  WidgetDisplayStock: boolean;
  WidgetPageDescription: string;
  WidgetToken: string;
  WidgetScript: string;
  SearchScopeId: number;
}

export enum PartnerTypeEnum {
  GardenCenter = 1,
  Agency = 2,
  CommunityBotanicalGarden = 3,
  PlantBrand = 4
}