import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';

export class ValidationService {

  static emailValidator(control: AbstractControl) {
    if (!control.value) return null;
    if (control.value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      return null;
    } else {
      return { 'invalidEmailAddress': true };
    }
  }

  static passwordValidator(control: AbstractControl) {
    if (!control.value) return null;
    if (control.value.match(/^((?=.*\d)(?=.*[a-zA-Z])(?=.*[@#!$%]).{8,40})$/)) {
      return null;
    } else {
      return { 'invalidPassword': true };
    }
  }

  static phoneValidator(control: AbstractControl) {
    if (!control.value) return null;
    if (control.value.match(/^\d{10}$/)) {
      return null;
    } else {
      return { 'invalidPhone': true };
    }
  }

  static checkDateValidation(control: UntypedFormGroup): ValidationErrors | null {
    const today = new Date();

    try {
      const startingDatefield = control.get('startDate').value;
      const endingDatefield = control.get('endDate').value;
      if ((startingDatefield === '') && (endingDatefield === '')) {
        return null;
      } else {
        if (today >= startingDatefield) { //compare to current date
          return { 'effectiveStartDatetime': false };

        } else
          if (startingDatefield > endingDatefield && endingDatefield) {
            return null;

          } else {
            return null;
          }
      }
    } catch (err) {
      console.error(err);
      return { 'invalidData': false };
    }
  };

  static latitudeValidator(value: string): boolean {
    let isValid = false;
    if (value.match(/^[+-]?(([1-8]?[0-9])(\.[0-9]{1,6})?|90(\.0{1,6})?)$/)) {
      isValid = true;
    }

    return isValid;
  }

  static longitudeValidator(value: string): boolean {
    let isValid = false;
    if (value.match(/^[+-]?((([1-9]?[0-9]|1[0-7][0-9])(\.[0-9]{1,6})?)|180(\.0{1,6})?)$/)) {
      isValid = true;
    }

    return isValid;
  }
}
