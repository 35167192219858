import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { HerbAttributes, PlantDetailModel, PlantImageModel, HerbModel } from '../models/plant.model';
import { PlantFilterModel, GenericResponseModel } from '../models';
import { Utils } from '../helpers/utils';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PlantsService {

  filter: PlantFilterModel = new PlantFilterModel();
  lastSeenPlantId: number = 0;

  constructor(private http: HttpClient) {
  }

  loadPlantAttributes(plantId: number) {
    return this.http.get<HerbAttributes>(`${environment.apiUrl}/plants/${plantId}/Attributes/?all=1`);
  }

  searchPlants(filter: any) {
    return this.http.get<any>(`${environment.apiUrl}/plants/0/Search/?` + Utils.qs(filter));
  }

  getSinglePlant(pc: number) {
    return this.http.get<any>(`${environment.apiUrl}/Plants/${pc}/GetSinglePlant/`);
  }

  getPrimaryImage(id: number) {
    return this.http.get<GenericResponseModel>(`${environment.apiUrl}/Plants/${id}/PrimaryImage/`);
  }

  savePlant(id: number, request: PlantDetailModel) {
    return this.http.post<GenericResponseModel>(`${environment.apiUrl}/plants/${id}/`, request);
  }

  searchPlantImages(id: number, filter: any) {
    return this.http.get<any>(`${environment.apiUrl}/Plants/${id}/Images/?` + Utils.qs(filter));
  }

  savePlantImage(id: number, request: PlantImageModel) {
    return this.http.post<GenericResponseModel>(`${environment.apiUrl}/plants/${id}/Images/`, request);
  }

  deletePlantImage(plantId: number, plantImageId: number, isDeleted: boolean = false) {
    return this.http.delete<GenericResponseModel>(`${environment.apiUrl}/plants/${plantId}/Images/${plantImageId}?del=${isDeleted ? 1 : 0}`);
  }

  deletePlant(id: number) {
    return this.http.delete<GenericResponseModel>(`${environment.apiUrl}/plants/${id}/`);
  }

  toggleDefaultPlantImage(plantId: number, plantImageId: number) {
    const request = { Id: plantId, LinkId: plantImageId };
    return this.http.put<GenericResponseModel>(`${environment.apiUrl}/plants/${plantId}/ToggleDefault/${plantImageId}/`, request);
  }

  toggleBatchPlantImageStatus(plantId: number, cmd: string, opts: string) {
    const request = { Id: plantId, Name: opts };
    return this.http.put<GenericResponseModel>(`${environment.apiUrl}/plants/${plantId}/BatchPlantImageStatus/${cmd}/`, request);
  }

  acceptRejectGrommerImages(actionId: number, ids: number[]) {
    return this.http.put<GenericResponseModel>(`${environment.apiUrl}/plants/${actionId}/AcceptRejectImages/`, ids);
  }

  completeGrommerPlant(plantId: number, groomerStatus: number) {
    return this.http.put<GenericResponseModel>(`${environment.apiUrl}/plants/${plantId}/CompleteGroomerPlant/${groomerStatus}/`, null);
  }

  rejectGrommerPlant(plantId: number, rejectionReasons: number[]): Observable<GenericResponseModel> {
    return this.http.put<GenericResponseModel>(`${environment.apiUrl}/plants/${plantId}/RejectGroomerPlant/`, rejectionReasons);
  }

  moveImageToPlant(imageId: number, plantId: number) {
    return this.http.post<GenericResponseModel>(`${environment.apiUrl}/plants/${imageId}/Move/${plantId}/`, null);
  }

  selectGroomerPlant(plantId: number) {
    return this.http.put<GenericResponseModel>(`${environment.apiUrl}/plants/${plantId}/SelectGroomerPlant/`, null);
  }

  groomPlant(plantId: number) {
    return this.http.put<GenericResponseModel>(`${environment.apiUrl}/plants/${plantId}/GroomPlant/`, null);
  }

  linkPlantToGallery(plantId: number, userImageId: number): Observable<GenericResponseModel> {
    const apiEndpoint = `/plants/${plantId}/LinkPlantGallery/${userImageId}`;
    return this.http.put<GenericResponseModel>(environment.apiUrl + apiEndpoint, { Id: plantId, LinkId: userImageId });
  }

  searchTerms(filter: any) {
    return this.http.get<any>(`${environment.apiUrl}/plants/0/SearchTerm/?` + Utils.qs(filter));
  }

  getSearchTermResults(id: number, filter: any): Observable<any[]> {
    return this.http.get<any>(`${environment.apiUrl}/plants/${id}/SearchTermResults/?` + Utils.qs(filter));
  }

  resolveSearchTerms(status: number, opts: string) {
    const request = { Id: status, Name: opts };
    return this.http.put<GenericResponseModel>(`${environment.apiUrl}/plants/0/SearchTerm/`, request);
  }

  downloadImages(ids: string): Observable<any> {
    const apiUrl = `/plants/0/Download/?key=${ids}&sp=0`;
    return this.http.get<any>(environment.apiUrl + apiUrl, { responseType: 'blob' as 'json' });
  }

  searchPopularPlantsPartner(id: number, filter: any): Observable<HerbModel[]> {
    return this.http.get<HerbModel[]>(`${environment.apiUrl}/plants/${id}/MostPopularPlants/?` + Utils.qs(filter))
      .pipe(
        catchError(err => {
          console.error(err);
          return EMPTY;
        })
      );
  }

  getPlantsGroomingReport(id: number, filter: any) {
    return this.http.get<any>(`${environment.apiUrl}/Plants/${id}/GroomingReport/?` + Utils.qs(filter));
  }

  getPlantsGroomingReportCsv(id: number, filter: any) {
    return this.http.get<any>(`${environment.apiUrl}/Plants/${id}/GroomingReportCsv/?` + Utils.qs(filter));
  }

}
